<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text">
        <v-icon class="white--text" left light>{{ icons.mdiAccountCircle }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon left>{{ icons.mdiPlus }}</v-icon>
        Roles Info
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="role.user_type"
                    color="secondary"
                    label="User Type"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" rounded @click.prevent="saveRole">
              <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'role' })">
              <v-icon right dark>{{ icons.mdiClose }}</v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  mdiAccountTie,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiAccountCircle,
  mdiAccountKey,
  mdiAccountEdit,
} from '@mdi/js'
export default {
  components: {},

  created: function () {
    this.getRoleId()
  },
  data() {
    return {
      roles: [],
      role: {
        user_type: '',
      },
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiAccountCircle,
        mdiAccountKey,
        mdiAccountEdit,
      },
    }
  },
  methods: {
    getRoleId: function () {
      this.$store
        .dispatch(`role/editRole`, {
          role_id: this.$route.params.id,
        })
        .then(response => {
          ;(this.role = this.fetchRoles), response
        })
    },

    saveRole: function () {
      this.$store
        .dispatch(`role/updateNewRole`, {
          role_id: this.$route.params.id,
          data: this.role,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record updated successfully',
          })
          this.$router.push({ name: 'role' })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  computed: {
    ...mapGetters({
      fetchRoles: 'role/fetchRoles',
    }),
  },
}
</script>

<style lang="scss" scoped>
</style>